/*
 * @Description: 项目管理api
 * @Autor: WangYuan
 * @Date: 2021-09-22 15:51:56
 * @LastEditors: WangYuan
 * @LastEditTime: 2022-02-10 20:15:44
 */
import request from '@/utils/request'

/**
 * 查询租户列表
 */
/*export const fetchList = async query =>
    request({ url: '/admin/tenant/list', method: 'get', params: query })*/



export const fetchList = (query) => {
    return request({
        url: '/admin/tenant/list',
        method: 'get',
        params: query
    });
};

/*
export function fetchList(query?: object) {
    return request({
        url: '/admin/tenant/list',
        method: 'get',
        params: query,
    });
}*/
