<template>
	<div style="position: relative">
		<div class="verify-img-out">
			<div
				class="verify-img-panel"
				:style="{
					width: setSize.imgWidth,
					height: setSize.imgHeight,
					'background-size': setSize.imgWidth + ' ' + setSize.imgHeight,
					'margin-bottom': vSpace + 'px',
				}"
			>
				<div class="verify-refresh" style="z-index: 3" @click="refresh" v-show="showRefresh">
					<i class="iconfont icon-refresh"></i>
				</div>
				<img
					:src="'data:image/png;base64,' + pointBackImgBase"
					ref="canvas"
					alt=""
					style="width: 100%; height: 100%; display: block"
					@click="bindingClick ? canvasClick($event) : undefined"
				/>

				<div
					v-for="(tempPoint, index) in tempPoints"
					:key="index"
					class="point-area"
					:style="{
						'background-color': '#1abd6c',
						color: '#fff',
						'z-index': 9999,
						width: '20px',
						height: '20px',
						'text-align': 'center',
						'line-height': '20px',
						'border-radius': '50%',
						position: 'absolute',
						top: parseInt(tempPoint.y - 10) + 'px',
						left: parseInt(tempPoint.x - 10) + 'px',
					}"
				>
					{{ index + 1 }}
				</div>
			</div>
		</div>
		<!-- 'height': this.barSize.height, -->
		<div
			class="verify-bar-area"
			:style="{ width: setSize.imgWidth, color: this.barAreaColor, 'border-color': this.barAreaBorderColor, 'line-height': this.barSize.height }"
		>
			<span class="verify-msg">{{ text }}</span>
		</div>
	</div>
</template>


<script type="text/babel">
/**
 * VerifyPoints
 * @description 点选
 * */
/*import {aesEncrypt} from "./../utils/ase.js"
import {myRequest} from "../utils/request.js"
import {reqCheck, reqGet} from "../api";*/

import { resetSize } from '../utils/util';
import { aesEncrypt } from '../utils/ase';
import { reqGet, reqCheck } from '../api/index';


export default {
  name: 'VerifyPoints',
  props: {
    //弹出式pop，固定fixed
    mode: {
      type: String,
      default: 'fixed'
    },
    captchaType:{
      type:String,
    },
    //间隔
    vSpace: {
      type: Number,
      default: 5
    },
    imgSize: {
      type: Object,
      default() {
        return {
          width: '310px',
          height: '155px'
        }
      }
    },
    barSize: {
      type: Object,
      default() {
        return {
          width: '310px',
          height: '40px'
        }
      }
    },
    defaultImg: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      secretKey:'',        //后端返回的加密秘钥 字段
      checkNum:3,                 //
      fontPos: [],                // 选中的坐标信息
      checkPosArr: [],            //用户点击的坐标
      num: 1,                     //点击的记数
      pointBackImgBase:'',        //后端获取到的背景图片
      poinTextList:[],            //后端返回的点击字体顺序
      backToken:'',               //后端返回的token值
      imgRand: 0,                 //随机的背景图片
      setSize: {
        imgHeight: 0,
        imgWidth: 0,
        barHeight: 0,
        barWidth: 0
      },
      showImage: true,
      tempPoints: [],
      text: '',
      barAreaColor: '#fff',
      barAreaBorderColor: "#fff",
      showRefresh: true,
      bindingClick: true,
      imgLeft:'' ,
      imgTop:'',
    }
  },
  computed: {
    resetSize() {
      return resetSize;
    },
  },
  methods: {
    init() {

   /*   //加载页面
      fontPos.splice(0, fontPos.length);
      checkPosArr.splice(0, checkPosArr.length);
      num.value = 1;
      getPictrue();
      nextTick(() => {
        let { imgHeight, imgWidth, barHeight, barWidth } = resetSize(proxy);
        setSize.imgHeight = imgHeight;
        setSize.imgWidth = imgWidth;
        setSize.barHeight = barHeight;
        setSize.barWidth = barWidth;
        proxy.$parent.$emit('ready', proxy);
      });*/



      //加载页面
      this.fontPos.splice(0, this.fontPos.length)
      this.checkPosArr.splice(0, this.checkPosArr.length)
      this.num = 1
      this.getPictrue();
      this.$nextTick(() => {
        this.refresh();


        let setSize = this.resetSize(this); //重新设置宽度高度

        for (let key in setSize) {
          this.$set(this.setSize, key, setSize[key]);
        }


        this.$parent.$emit('ready', this)
      })


    },
    canvasClick(e) {
      const query = uni.createSelectorQuery().in(this);
      query.select('#image').boundingClientRect(data => {
        this.imgLeft =Math.ceil(data.left)
        this.imgTop =Math.ceil(data.top)
        this.checkPosArr.push(this.getMousePos(this.$refs.canvas, e));
        if (this.num == this.checkNum) {
          this.num = this.createPoint(this.getMousePos(this.$refs.canvas, e));
          //按比例转换坐标值
          this.checkPosArr = this.pointTransfrom(this.checkPosArr,this.imgSize);
          //等创建坐标执行完
          setTimeout(() => {
            //发送后端请求
            var captchaVerification =this.secretKey? aesEncrypt(this.backToken+'---'+JSON.stringify(this.checkPosArr),this.secretKey):this.backToken+'---'+JSON.stringify(this.checkPosArr)
            let data = {
              captchaType:this.captchaType,
              "pointJson":this.secretKey? aesEncrypt(JSON.stringify(this.checkPosArr),this.secretKey):JSON.stringify(this.checkPosArr),
              "token":this.backToken
            }




            this.checkCodeResult(data,captchaVerification);




          }, 400);
        }
        if (this.num < this.checkNum) {
          this.num = this.createPoint(this.getMousePos(this.$refs.canvas, e));
        }
      }).exec();
    },




    checkCodeResult(data,captchaVerification){

      reqCheck(data).then((response) => {
        let res = response.data;

        //console.log("====reqCheck111111=====:",res.repCode);

        if (res.repCode == '0000') {
          this.barAreaColor = '#4cae4c';
          this.barAreaBorderColor = '#5cb85c';
          this.text = '验证成功';
          this.bindingClick = false;

          if (this.mode == 'pop') {
            setTimeout(() => {
              //proxy.$parent.clickShow = false;
              this.$parent.clickShow = false;
              this.refresh();
            }, 1500);
          }


          this.$parent.$emit('success',captchaVerification)
         // proxy.$parent.$emit('success', { captchaVerification });


        } else {

          this.$parent.$emit('error', this)
          this.barAreaColor = '#d9534f'
          this.barAreaBorderColor = '#d9534f'
          this.text = '验证失败'
          setTimeout(() => {
            this.refresh();
          }, 700);




        }


      });






     /* this.$Request.post(this.$api.verify.checkCode,'post',data).then(result => {
            let res = result.data;

            if (res.code == 0) {
              console.log("验证成功验证成功验证成功验证成功验证成功验证成功");
              this.barAreaColor = '#4cae4c'
              this.barAreaBorderColor = '#5cb85c'
              this.text = '验证成功'
              this.bindingClick = false
              setTimeout(() => {
                if (this.mode == 'pop') {
                  this.$parent.clickShow = false;
                }
                this.refresh();
              }, 1500)

              //this.$parent.$emit('success', {captchaVerification})
              this.$parent.$emit('success',res.data)

            } else {
              this.$parent.$emit('error', this)
              this.barAreaColor = '#d9534f'
              this.barAreaBorderColor = '#d9534f'
              this.text = '验证失败'
              setTimeout(() => {
                this.refresh();
              }, 700);

            }
          }
      )*/

    },




    //获取坐标
    getMousePos: function (obj, e) {
      let position = {
        x:Math.ceil(e.detail.x)-this.imgLeft,
        y:Math.ceil(e.detail.y)-this.imgTop,
      }
      return position
    },
    //创建坐标点
    createPoint: function (pos) {
      this.tempPoints.push(Object.assign({}, pos))
      return ++this.num;
    },
    refresh: function () {
      this.tempPoints.splice(0, this.tempPoints.length)
      this.barAreaColor = '#000'
      this.barAreaBorderColor = '#ddd'
      this.bindingClick = true

      this.fontPos.splice(0, this.fontPos.length)
      this.checkPosArr.splice(0, this.checkPosArr.length)
      this.num = 1

      this.getPictrue();

      // this.text = '验证失败'
      this.showRefresh = true
    },
    // 请求背景图片和验证图片
    getPictrue(){


      let data = {
        captchaType: this.captchaType,
      };

      reqGet(data).then((response) => {
        let res = response.data;
        if (res.repCode == '0000') {
          this.pointBackImgBase = res.repData.originalImageBase64
          this.backToken = res.repData.token
          this.secretKey = res.repData.secretKey
          this.poinTextList = res.repData.wordList
          this.text = '请依次点击【' + this.poinTextList.join(",") + '】'
        } else {
          this.text = res.repMsg;
        }
      });


     /* let data = {
        captchaType:this.captchaType,
        clientUid: uni.getStorageSync('point'),
        ts: Date.now(), // 现在的时间戳
      }
      //?captchaType='+data.captchaType+'&clientUid='+data.clientUid+'&ts='+data.ts
      myRequest({
        url: '/rs-base/captcha/get', //仅为示例，并非真实接口地址。
        data:data,
        method:"POST",
      }).then((result) => {
        let res = result.data
        console.log("----res=====:",res);
        if (res.repCode == "0000") {
          this.pointBackImgBase = res.repData.originalImageBase64
          this.backToken = res.repData.token
          this.secretKey = res.repData.secretKey
          this.poinTextList = res.repData.wordList
          this.text = '请依次点击【' + this.poinTextList.join(",") + '】'
        }
        // 判断接口请求次数是否失效
        if(res.repCode == '6201') {
          this.pointBackImgBase = null
        }
      })*/


    },
    //坐标转换函数
    pointTransfrom(pointArr,imgSize){
      var newPointArr = pointArr.map(p=>{
        let x = Math.round(310 * p.x/parseInt(imgSize.width))
        let y =Math.round(155 * p.y/parseInt(imgSize.height))
        return {x,y}
      })
      // console.log(newPointArr,"newPointArr");
      return newPointArr
    },
  },
  watch: {
    // type变化则全面刷新
    type: {
      immediate: true,
      handler() {
        this.init()
      }
    }
  },
  mounted() {
    console.log(this.defaultImg)
  }
}
</script>


<!--<script type="text/babel">
/**
 * VerifyPoints
 * @description 点选
 * */
import { resetSize } from '../utils/util';
import { aesEncrypt } from '../utils/ase';
import { reqGet, reqCheck } from '../api/index';
import { onMounted, reactive, ref, nextTick, toRefs, getCurrentInstance } from 'vue';
export default {
	name: 'VerifyPoints',
	props: {
		//弹出式pop，固定fixed
		mode: {
			type: String,
			default: 'fixed',
		},
		captchaType: {
			type: String,
		},
		//间隔
		vSpace: {
			type: Number,
			default: 5,
		},
		imgSize: {
			type: Object,
			default() {
				return {
					width: '310px',
					height: '155px',
				};
			},
		},
		barSize: {
			type: Object,
			default() {
				return {
					width: '310px',
					height: '40px',
				};
			},
		},
	},
	setup(props) {
		const { mode, captchaType } = toRefs(props);
		const { proxy } = getCurrentInstance();
		let secretKey = ref(''), //后端返回的ase加密秘钥
			checkNum = ref(3), //默认需要点击的字数
			fontPos = reactive([]), //选中的坐标信息
			checkPosArr = reactive([]), //用户点击的坐标
			num = ref(1), //点击的记数
			pointBackImgBase = ref(''), //后端获取到的背景图片
			poinTextList = reactive([]), //后端返回的点击字体顺序
			backToken = ref(''), //后端返回的token值
			setSize = reactive({
				imgHeight: 0,
				imgWidth: 0,
				barHeight: 0,
				barWidth: 0,
			}),
			tempPoints = reactive([]),
			text = ref(''),
			barAreaColor = ref(undefined),
			barAreaBorderColor = ref(undefined),
			showRefresh = ref(true),
			bindingClick = ref(true);

		const init = () => {
			//加载页面
			fontPos.splice(0, fontPos.length);
			checkPosArr.splice(0, checkPosArr.length);
			num.value = 1;
			getPictrue();
			nextTick(() => {
				let { imgHeight, imgWidth, barHeight, barWidth } = resetSize(proxy);
				setSize.imgHeight = imgHeight;
				setSize.imgWidth = imgWidth;
				setSize.barHeight = barHeight;
				setSize.barWidth = barWidth;
				proxy.$parent.$emit('ready', proxy);
			});


		};
		onMounted(() => {
			// 禁止拖拽
			init();
			proxy.$el.onselectstart = function () {
				return false;
			};
		});
		const canvas = ref(null);
		const canvasClick = (e) => {
			checkPosArr.push(getMousePos(canvas, e));
			if (num.value == checkNum.value) {
				num.value = createPoint(getMousePos(canvas, e));
				//按比例转换坐标值
				let arr = pointTransfrom(checkPosArr, setSize);
				checkPosArr.length = 0;
				checkPosArr.push(...arr);
				//等创建坐标执行完
				setTimeout(() => {
					// var flag = this.comparePos(this.fontPos, this.checkPosArr);
					//发送后端请求
					var captchaVerification = secretKey.value
						? aesEncrypt(backToken.value + '-&#45;&#45;' + JSON.stringify(checkPosArr), secretKey.value)
						: backToken.value + '-&#45;&#45;' + JSON.stringify(checkPosArr);

					let data = {
						captchaType: captchaType.value,
						pointJson: secretKey.value ? aesEncrypt(JSON.stringify(checkPosArr), secretKey.value) : JSON.stringify(checkPosArr),
						token: backToken.value,
					};


					reqCheck(data).then((response) => {
						let res = response.data;

            console.log("====reqCheck111111=====:",res.repCode);

						if (res.repCode == '0000') {
							barAreaColor.value = '#4cae4c';
							barAreaBorderColor.value = '#5cb85c';
							text.value = '验证成功';
							bindingClick.value = false;

							if (mode.value == 'pop') {
								setTimeout(() => {
									proxy.$parent.clickShow = false;
									refresh();
								}, 1500);
							}



							proxy.$parent.$emit('success', { captchaVerification });


						} else {
							proxy.$parent.$emit('error', proxy);
							barAreaColor.value = '#d9534f';
							barAreaBorderColor.value = '#d9534f';
							text.value = '验证失败';
							setTimeout(() => {
								refresh();
							}, 700);
						}


					});


				}, 400);
			}
			if (num.value < checkNum.value) {
				num.value = createPoint(getMousePos(canvas, e));
			}
		};
		//获取坐标
		const getMousePos = function (obj, e) {
			var x = e.offsetX;
			var y = e.offsetY;
			return { x, y };
		};
		//创建坐标点
		const createPoint = function (pos) {
			tempPoints.push(Object.assign({}, pos));
			return num.value + 1;
		};
		const refresh = function () {
			tempPoints.splice(0, tempPoints.length);
			barAreaColor.value = '#000';
			barAreaBorderColor.value = '#ddd';
			bindingClick.value = true;
			fontPos.splice(0, fontPos.length);
			checkPosArr.splice(0, checkPosArr.length);
			num.value = 1;
			getPictrue();
			text.value = '验证失败';
			showRefresh.value = true;
		};

		// 请求背景图片和验证图片
		function getPictrue() {
			let data = {
				captchaType: captchaType.value,
			};
			reqGet(data).then((response) => {
				let res = response.data;
				if (res.repCode == '0000') {
					pointBackImgBase.value = res.repData.originalImageBase64;
					backToken.value = res.repData.token;
					secretKey.value = res.repData.secretKey;
					poinTextList.value = res.repData.wordList;
					text.value = '请依次点击【' + poinTextList.value.join(',') + '】';
				} else {
					text.value = res.repMsg;
				}
			});
		}
		//坐标转换函数
		const pointTransfrom = function (pointArr, imgSize) {
			var newPointArr = pointArr.map((p) => {
				let x = Math.round((310 * p.x) / parseInt(imgSize.imgWidth));
				let y = Math.round((155 * p.y) / parseInt(imgSize.imgHeight));
				return { x, y };
			});
			return newPointArr;
		};
		return {
			secretKey,
			checkNum,
			fontPos,
			checkPosArr,
			num,
			pointBackImgBase,
			poinTextList,
			backToken,
			setSize,
			tempPoints,
			text,
			barAreaColor,
			barAreaBorderColor,
			showRefresh,
			bindingClick,
			init,
			canvas,
			canvasClick,
			getMousePos,
			createPoint,
			refresh,
			getPictrue,
			pointTransfrom,
		};
	},
};
</script>-->
