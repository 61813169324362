<!--
 * @Description: 登录
 * @Autor: WangYuan
 * @Date: 2021-08-18 11:19:34
 * @LastEditors: WangYuan
 * @LastEditTime: 2022-02-12 17:37:20
-->
<template>
  <div class="login">

    <div style="position: fixed;top:30px;right:50px;">
      <el-dropdown trigger="click" placement="bottom-end" @command="handleCommand">

        <el-button circle>
          <i class="el-icon-s-operation" style="font-size:20px;font-weight: bold;"></i>
        </el-button>

        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item v-for="item in tenantList" :key="item.id" :command="item">
              {{ item.name }}
              <span class="ml8" v-if="tenantId==item.id">
                <i class="el-icon-check" style="font-size:20px;font-weight: bold;"></i>
              </span>


            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>

    <div class="login-content">
      <div class="login-content-left">
        <!-- tabs -->
        <ul class="flex col-bottom mb60" style="display: flex;justify-content: center">
          <li
            v-for="tab in tabs"
            :key="tab.value"
            class="tab mr20"
            :class="[active == tab.value ? 'tab-active' : '']"
            @click="active = tab.value"
          >
            {{tenantList.length>0?getTenantName()+'后台系统':''}}
          </li>
        </ul>

        <!-- 登录 -->
        <template v-if="active == 'login'">
          <el-form :model="loginForm" ref="login" label-width="0">
            <el-form-item
              prop="username"
              class="mb40"
              :verify="{ minLen: 4, maxLen: 16, typeOptions: ['字母|数字'] }"
            >
              <el-input
                v-model="loginForm.username"
                placeholder="请输入账户名"
              ></el-input>
            </el-form-item>
            <el-form-item
              prop="password"
              class="mb70"
              :verify="{ passwordOptions: [6, 18, '字母|数字'] }"
            >
              <el-input
                v-model="loginForm.password"
                placeholder="输入密码登录"
                show-password
              ></el-input>
            </el-form-item>


            <el-form-item prop="code">
              <Verify
                  @success="verifySuccessLogin"
                  :mode="'pop'"
                  :captchaType="'blockPuzzle'"
                  fromPage="newLogin"
                  :imgSize="{ width: '330px', height: '155px' }"
                  ref="verify"
              />
            </el-form-item>


          </el-form>

          <el-button
            class="w-100 h48 f-white bg-theme r3 f18 lb-4"
            type="primary"
            @click="login"
            >登录</el-button
          >

<!--          <div
            class="flex row-right mt25 f15 f-theme pointer"
            @click="active = 'register'"
          >
            免费注册
          </div>-->

        </template>

        <!-- 注册
        <template v-if="active == 'register'">
          <el-form :model="registerForm" ref="register" label-width="0">
            <el-form-item
              prop="account"
              class="mb40"
              :verify="{ minLen: 4, maxLen: 16, typeOptions: ['字母|数字'] }"
            >
              <el-input
                v-model="registerForm.account"
                placeholder="请输入注册账户"
              ></el-input>
            </el-form-item>
            <el-form-item
              prop="password"
              class="mb40"
              :verify="{ passwordOptions: [6, 18, '字母|数字'] }"
            >
              <el-input
                v-model="registerForm.password"
                placeholder="请输入注册密码"
                show-password
              ></el-input>
            </el-form-item>
            <el-form-item prop="userName" class="mb70" :verify="{ maxLen: 10 }">
              <el-input
                v-model="registerForm.userName"
                placeholder="请输入用户名"
              ></el-input>
            </el-form-item>

            <el-form-item prop="code">
              <Verify
                  @success="verifySuccess"
                  :mode="'pop'"
                  :captchaType="'blockPuzzle'"
                  fromPage="newLogin"
                  :imgSize="{ width: '330px', height: '155px' }"
                  ref="verify"
              />



            </el-form-item>

          </el-form>



          <el-button
            class="w-100 h48 f-white bg-theme r3 f18 lb-4"
            type="primary"
            @click="register"
            >注册</el-button
          >
        </template>

        -->

      </div>
      <div class="login-content-right">
        <img class="w-100" src='../assets/image/logo.jpeg'/>
      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters, mapMutations} from "vuex";
import { login, register,getUserInfo } from "@/api/user";
import Verify from "@/components/verifition/Verify";
import {fetchList} from "../api/tenant";

//先获取到code

export default {
  name: "login",
  components: {
    Verify
  },
  async created() {

    this.logout();
    //获取租户列表
    let res = await fetchList();
    console.log("====租户列表===:",res.data);
    this.tenantList=res.data;
    this.setLTenantId(this.tenantId);

  },

  data() {
    return {
      active: "login",
      loginForm: {
        username: '',
        password: '',
        grant_type: 'password',
        scope: 'server',
        code:'',
        randomStr:'blockPuzzle'
      },
      registerForm: {
        account: '', 
        password: '',
        userName: ''
      },
      tabs: [
        { label: "密码登录", value: "login" },
       /* { label: "免费注册", value: "register" },*/
      ],
      tenantList:[],//租户列表
      tenantId:'1'

    };
  },

  watch: {
    active: {
      handler(newVal) {
        switch(newVal) {
          case 'login': 
            this.loginForm = {
              username: '',
              password: '',
              grant_type: 'password',
              scope: 'server',
              code:'',
              randomStr:'blockPuzzle'
            };
            break;
          case 'register':
            this.registerForm = {
              account: '', 
              password: '',
              userName: ''
            };
            break;
          default:
            break;
        }
      },
    },
  },


  methods: {
    ...mapMutations(["setToken","setRToken", "setUserInfo", "logout",'setLTenantId']),
   // ...mapGetters(["tenantId"]),


    //获取租户名字
     getTenantName(){
     // this.tenantList

         let index = this.tenantList.findIndex(
             (item) => item.id == this.tenantId
         );
         return this.tenantList[index].name;


    },
    // 登录
    async login() {
      this.$refs["login"].validate(async (valid) => {
        if (valid) {
          this.$refs.verify.show();
         /* let res = await login(this.loginForm);

          if (res.status == "10000") {
          // if (res.code == "0") {
            this.$notify({
              title: "登陆成功",
              message: "快去体验可视化给构建商城吧！",
              type: "success",
            });
            this.setToken(res.token);
            // this.setToken('1AgO-4FkO7nUyMA0cQ74W_z_-WljFhzJ970_nItmhM5mPoQBlFN4HoPUFPrh7aCo-v-Y22mMTXjDQ6NjiciQhNKO1jWJ5hetKbSwRb42VXmMBaRX0sTQMgwwasu4eAIh');
            this.setUserInfo(res.userInfo);
            this.$router.push({ name: "managet" });
          } else {
            this.$notify({
              title: "登录失败",
              message: res.message,
              type: "warning",
            });
          }*/


        }
      });
    },
    /**
     * 写入租户信息到本地存储的函数，方便 axios 每次请求获取 tab 范围内的租户ID。
     * @param command - 需要存储的租户ID。
     */
    handleCommand(tenant){
      this.setLTenantId(tenant.id);
      this.tenantId=tenant.id;
    },

    /**
     * 选择的租户高亮显示
     * @param {string} id - 租户id
     * @returns {Object} - 返回包含'background-color'和color属性的对象
     */
     selectBgFlag(id) {
      this.setLTenantId(id);
      this.tenantId=id;
     //return id ==this.tenantId ? 'Check' : '';
    },

    async verifySuccessLogin(params) {
      this.loginForm.code = params;
      let res = await login(this.loginForm);

      /*this.$notify({
        title: "登录成功",
        message: "快去体验可视化给构建商城吧！",
        type: "success",
      });*/
      //console.log("res.access_token:",res.access_token);
      console.log("===res===:",res.access_token);
      console.log("===res===:",res.refresh_token);
      this.setToken(res.access_token);
      this.setRToken(res.refresh_token);
      //getUserInfo

      console.log("====res.access_token===:",res.access_token);
      if(res.access_token){
        let result = await getUserInfo();
        console.log("result:",result);
        this.setUserInfo(result.data.sysUser);//用户信息
        this.$router.push({ name: "managet" });
      }


      /*if (res.status == "10000") {
        // if (res.code == "0") {
        this.$notify({
          title: "登陆成功",
          message: "快去体验可视化给构建商城吧！",
          type: "success",
        });
        this.setToken(res.token);
        // this.setToken('1AgO-4FkO7nUyMA0cQ74W_z_-WljFhzJ970_nItmhM5mPoQBlFN4HoPUFPrh7aCo-v-Y22mMTXjDQ6NjiciQhNKO1jWJ5hetKbSwRb42VXmMBaRX0sTQMgwwasu4eAIh');
        this.setUserInfo(res.userInfo);
        this.$router.push({ name: "managet" });

      } else {
        this.$notify({
          title: "登录失败",
          message: res.message,
          type: "warning",
        });
      }*/

    },
    // 注册
    register() {
      this.$refs["register"].validate(async (valid) => {
        if (valid) {
          let res = await register(this.registerForm);
          if (res.status == "10000") {
          // if (res.code == "0") {
            this.$notify({
              title: "注册成功",
              message: "账户已注册成功，快去登录使用吧",
              type: "success",
            });
            this.active = "login";
            setTimeout(() => {
              this.$refs["login"].resetFields();
            }, 0);
          } else {
            this.$notify({
              title: "注册失败",
              type: "warning",
              message: res.message,
            });
          }
        }
      });
    },

  },
};



</script>

<style lang="scss" scoped>
@import "../scss/variable";
::v-deep .el-input__inner {
  border: 0;
  border-radius: 0;
  padding: 0;
  font-size: 16px;
  border-bottom: solid 1px #dcdee0 !important;
}

::v-deep .el-input__icon {
  font-size: 20px;
}

.login {
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: #f2f3f5;
  overflow: hidden;

  .login-content {
    display: flex;
    height: 500px;
    margin-top: 200px;

    .login-content-left {
      width: 550px;
      margin-right: 20px;
      border-radius: 6px;
      background: #fff;
      padding: 50px 56px 60px;

      .tab {
        font-size: 20px;
        color: $color-grey;
        cursor: pointer;
      }

      .tab-active {
        font-size: 24px;
        color: #323233;
        //font-weight: 500;
        font-weight: bold;
      }
    }

    .login-content-right {
      display: flex;
      align-items: center;
      width: 320px;
      border-radius: 6px;
      background: #fff;
    }
  }
}

::v-deep  .el-button.is-circle{
  padding:8px;
}

</style>
